<template>
  <div class="footer">
    <div class="company col-12 col-sm-2">
      <span>EL Portal Serviços</span>
    </div>
    <div class="nameSystem col-12 col-sm-8">
      <span>© GPI - Gestão Pública Integrada</span>
    </div>
    <div class="version col-12 col-sm-2">
      <span> <strong> Versão </strong> {{ version }} </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    version: {
      type: String,
      default: '1.0.0',
    },
  },
};
</script>
<style lang="scss">
  .footer {
    background-color: #3b434c;
    min-height: 40px;

    font-size: 0.8em;
    font-family: "Open Sans", sans-serif;
    color: #a2abb7;
    display: flex;

    .company, .nameSystem, .version{
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 40px;
    }

  }

  @media (max-width: 768px) {
    .footer {
      display: grid;
      .company, .nameSystem, .version {
        min-block-size: max-content;
      }
    }
  }
</style>