const data = {
  enableSpinner: false,
  menuSelected: null,
  trbToken: null,
  keyRecaptcha: null,
  idCliente: null,
  idUsuario: null,
  idAmbiente: null,
  trbUser: null,
  trbPassword: null,
  serverApi: null,
  serverExec: null,
  urlSistema: null,
  urlAutenticacaoNfse: null
};

const getters = {
  getKeyRecaptcha: (state) => state.keyRecaptcha,
  getIdCliente: (state) => state.idCliente,
  getIdUsuario: (state) => state.idUsuario,
  getIdAmbiente: (state) => state.idAmbiente,
  getEnableSpinner: (state) => state.enableSpinner,
  getTrbUser: (state) => state.trbUser,
  getTrbPassword: (state) => state.trbPassword,
  getTrbToken: (state) => state.trbToken,
  getServerExec: (state) => state.serverExec,
  getUrlSistema: (state) => state.urlSistema,
  getUrlAutenticacaoNfse: (state) => state.urlAutenticacaoNfse,
  getServerApi: (state) => state.serverApi,
  getMenuSelected: (state) => state.menuSelected,
  
};

const mutations = {
  SET_KEYRECAPTCHA(state, payload) {
    state.keyRecaptcha = payload;
  },
  SET_IDCLIENTE(state, payload) {
    state.idCliente = payload;
  },
  SET_IDUSUARIO(state, payload) {
    state.idUsuario = payload;
  },
  SET_IDAMBIENTE(state, payload) {
    state.idAmbiente = payload;
  },
  SET_ENABLE_SPINNER(state, payload) {
    state.enableSpinner = payload;
  },
  SET_TRB_USER(state, payload) {
    state.trbUser = payload;
  },
  SET_TRB_PASSWORD(state, payload) {
    state.trbPassword = payload;
  },
  SET_TRB_TOKEN(state, payload) {
    state.trbToken = payload;
  },
  SET_SERVER_API(state, payload) {
    state.serverApi = payload;
  },
  SET_SERVER_EXEC(state, payload) {
    state.serverExec = payload;
  },
  SET_URL_SISTEMA(state, payload) {
    state.urlSistema = payload;
  },
  SET_URL_AUTENTICACAO_NFSE(state, payload) {
    state.urlAutenticacaoNfse = payload;
  },
  SET_MENU_SELECTED(state, payload) {
    state.menuSelected = payload;
  },

  SET_DATA(state, data) {
    state.keyRecaptcha =  data.keyRecaptcha;
    state.idCliente =  data.idCliente;
    state.idUsuario =  data.idUsuario;
    state.idAmbiente =  data.idAmbiente;
    state.trbUser =  data.trbUser;
    state.trbPassword =  data.trbPassword;
    state.serverApi =  data.serverApi;
    state.serverExec =  data.serverExec;
    state.urlSistema =  data.urlSistema;
    state.urlAutenticacaoNfse =  data.urlAutenticacaoNfse;
  },

};

const actions = {
  SET_KEY_RECAPTCHA({ commit }, payload) {
    commit('SET_KEY_RECAPTCHA', payload);
  },
  SET_ID_CLIENTE({ commit }, payload) {
    commit('SET_ID_CLIENTE', payload);
  },
  SET_ID_USUARIO({ commit }, payload) {
    commit('SET_ID_USUARIO', payload);
  },
  SET_ID_AMBIENTE({ commit }, payload) {
    commit('SET_ID_AMBIENTE', payload);
  },
  SET_ENABLE_SPINNER({ commit }, enable) {
    commit('SET_ENABLE_SPINNER', enable);
  },
  SET_TRB_USER({ commit }, enable) {
    commit('SET_TRB_USER', enable);
  },
  SET_TRB_PASSWORD({ commit }, enable) {
    commit('SET_TRB_PASSWORD', enable);
  },
  SET_TRB_TOKEN({ commit }, enable) {
    commit('SET_TRB_TOKEN', enable);
  },
  SET_SERVER_API({ commit }, enable) {
    commit('SET_SERVER_API', enable);
  },
  SET_SERVER_EXEC({ commit }, enable) {
    commit('SET_SERVER_EXEC', enable);
  },
  SET_MENU_SELECTED({ commit }, enable) {
    commit('SET_MENU_SELECTED', enable);
  },
  SET_URL_SISTEMA({ commit }, enable) {
    commit('SET_URL_SISTEMA', enable);
  },
  SET_URL_AUTENTICACAO_NFSE({ commit }, enable) {
    commit('SET_URL_AUTENTICACAO_NFSE', enable);
  },

  SET_DATA({ commit }, DATA) {
    commit('SET_DATA', DATA);
  },
};

export default {
  state: data,
  getters,
  actions,
  mutations,
};
