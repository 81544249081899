import { http } from '@/http';

const path = '/portal';

class PortalService {
    
  static async findFuncionalidades(idCliente, idUsuario) {
    try {
      const { data } = await http.get(`${path}/funcionalidades?idCliente=${idCliente}&idUsuario=${idUsuario}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar as funcionalidades.', error);
      throw error;
    }
  }

  static async findConfFuncionalidade(idCliente, idFuncionalidade) {
    try {
      const { data } = await http.get(`${path}/configruacaoFuncionalidade?idCliente=${idCliente}&idFuncionalidade=${idFuncionalidade}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar as configurações.', error);
      throw error;
    }
  }

  static async findFuncionalidadeContribuinteTipo(idCliente, idFuncionalidade, tipoFiltro) {
    try {
      const { data } = await http.get(`${path}/funcionalidadeContribuinteTipo?idCliente=${idCliente}&idFuncionalidade=${idFuncionalidade}&tipoFiltro=${tipoFiltro}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar as filtros.', error);
      throw error;
    }
  }

  static async getTrbToken(url, login) {
    try {
      const { data } = await http.post(`${path}/trbToken?url=${url}`, login);
      return data.token;
    } catch (error) {
      console.log('Erro ao buscar token.', error);
      throw error;
    }
  }

  static async getEnderecosByIdPessoa(idPessoa) {
    try {
      const { data } = await http.get(`${path}/enderecos?idPessoa=${idPessoa}`);
      return data;
    } catch (error) {
      console.log('Erro ao buscar endereços.', error);
      throw error;
    }
  }

}

export default PortalService;