import { createRouter, createWebHistory } from 'vue-router'
import LoginService from '@/services/login.service'

const routes = [
  {
    path: '',
    name: '',
    component: () => import(/* webpackChunkName: "" */ '../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
  },
  {
    path: '/homeCredito',
    name: 'homeCredito',
    component: () => import(/* webpackChunkName: "homeCredito" */ '../views/HomeCredito.vue'),
  },
  {
    path: '/documento',
    name: 'documento',
    component: () => import(/* webpackChunkName: "documento" */ '../views/documento/FormDocumento.vue'),
  },
  {
    path: '/extrato',
    name: 'extrato',
    component: () => import(/* webpackChunkName: "extrato" */ '../views/extrato/FormExtrato.vue'),
  },
  {
    path: '/dam',
    name: 'dam',
    component: () => import(/* webpackChunkName: "dam" */ '../views/dam/FormDam.vue'),
    meta: {
      reload: true,
    },
  },
  {
    path: '/itbi',
    name: 'itbi',
    component: () => import(/* webpackChunkName: "itbi" */ '../views/extrato/FormExtrato.vue'),
  },
  {
    path: '/extrato-credito',
    name: 'extrato-credito',
    component: () => import(/* webpackChunkName: "extrato-credito" */ '../views/extratoCredito/FormExtratoCredito.vue'),
  },
  { 
    path: '/credito',
    name: 'credito',
    component: () => import(/* webpackChunkName: "credito" */ '../views/credito/FormAdicionarInscricao.vue'),
  },
  { 
    path: '/regrasCredito',
    name: 'regrasCredito',
    component: () => import(/* webpackChunkName: "credito" */ '../views/RegrasCredito.vue'),
  },
  { 
    path: '/recuperarSenha',
    name: 'recuperarSenha',
    component: () => import(/* webpackChunkName: "credito" */ '../views/RecuperarSenha.vue'),
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log('navegacao');
  console.log(to.fullPath);
  if(to.fullPath.includes('/logout')){
    let currentUser = LoginService.currentUserValue;
    if(currentUser && currentUser.usuario){
      LoginService.logOut();
      return next({path: '/logout'});
    }
  } else if (!to.fullPath.includes('/login') && !to.fullPath.includes('/recuperarSenha')) {
    let currentUser = LoginService.currentUserValue;
    if(currentUser && currentUser.usuario){
      if (LoginService.isTokenExpired()) {
        LoginService.logOut();
        return next({path: '/logout'});
      }
    }
  }
  next();
});

export default router
