import { createStore, createLogger } from 'vuex'
import VuexPersist from 'vuex-persist';
// import createLogger from 'vuex/dist/logger';
import loggedStore from './logged.store';

const debug = process.env.NODE_ENV !== 'production';

const vuexPersist = new VuexPersist({
  key: '@portal-data',
  storage: window.localStorage,
});

const plugins = [vuexPersist.plugin];

export default createStore({
  modules: {
    loggedStore,
  },
  strict: debug,
  plugins: debug ? [...plugins, createLogger()] : plugins,
});
