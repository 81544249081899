<template>
  <ProgressSpinner
    v-if="$store.getters.getEnableSpinner"
    style="width: 50px; height: 50px"
    strokeWidth="5"
    animationDuration=".5s"
    fill="var(--surface-ground)"
  />
</template>
<script>
export default {
};
</script>
<style>
  .p-progress-spinner {
    position: fixed !important;
    top: 20px;
    right: 25px;
    z-index: 10000;
  }

  @keyframes p-progress-spinner-color {
    100%, 0% { stroke: #d62d20; }
    40% { stroke: #0057e7; }
    66% { stroke: #008744; }
    80%, 90% { stroke: #ffa700; }
  }
</style>