import { http } from '@/http';
import { BehaviorSubject } from 'rxjs';
import VueJwtDecode from 'vue-jwt-decode'

const path = '/usuario';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUserFinan')));

class LoginService {
    constructor() {
    }

    get currentUserValue() {
        return currentUserSubject.value;
    }
    get currentUser() {
        return currentUserSubject.asObservable();
    }

    isTokenExpired() {
        if (this.currentUserValue && this.currentUserValue.token) {
            const token = this.currentUserValue.token;
            try {
                const date = new Date(0);
                const decoded = VueJwtDecode.decode(token);
                date.setUTCSeconds(decoded.exp);
                return new Date().valueOf() > date.valueOf();
            } catch (err) {
                return false;
            }
        }
        return false;
    }

    updateStorage(data) {
        localStorage.removeItem('currentUserFinan');
        currentUserSubject.next(null);
        localStorage.setItem('currentUserFinan', JSON.stringify(data));
        currentUserSubject.next(data);
    }

    login(idCliente, login) {
        return http.post(`${path}/login?idCliente=${idCliente}`, JSON.stringify(login),
            { headers: { 'Content-Type': 'application/json; charset=UTF-8' } }).then(
                response => {
                    localStorage.setItem('currentUserFinan', JSON.stringify(response.data));
                    currentUserSubject.next(response.data);
                }
            );
    }

    validarCredenciar(idCliente, credenciamento){
        return http.post(`${path}/validarCredenciar?idCliente=${idCliente}`, credenciamento);
    }

    recuperarSenha(login, idCliente, urlSistema) {
        return http.post(`${path}/recuperarSenha?login=${login}&idCliente=${idCliente}&urlSistema=${urlSistema}`, null);
    }

    credenciar(idCliente, credenciar){
        credenciar.idCliente = idCliente;
        return http.post(`${path}/credenciar`, credenciar);
    }

    finalizarCredenciar(credenciar){
        return http.post(`${path}/finalizarCredenciar`, credenciar);
    }

    buscarUsuarioPorChave(chave) {
        return http.post(`${path}/buscarUsuarioPorChave?chave=${chave}`, null);
    }

    novaSenha(recuperaSenha) {
        return http.post(`${path}/novaSenha`, JSON.stringify(recuperaSenha),
        { headers: { 'Content-Type': 'application/json; charset=UTF-8' } });
    }


    logOut() {
        localStorage.removeItem('currentUserFinan');
        currentUserSubject.next(null);
        return http.post(`${path}/login/logout/`);
    }

    findAllLocalidadeByFiltro(filtro) {
        return http.get(`${path}/localidade/filter?filtro=${filtro}`, {headers: this.headers}).then(response => (this.data = response.data.elements));
    }

    findAllBairroByFiltro(idCliente, filtro) {
        return http.get(`${path}/bairro/filter?idCliente=${idCliente}&filtro=${filtro}`, {headers: this.headers}).then(response => (this.data = response.data.elements));
    }
    
    findAllLogradouroByFiltro(idCliente, filtro) {
        return http.get(`${path}/logradouro/filter?idCliente=${idCliente}&filtro=${filtro}`, {headers: this.headers}).then(response => (this.data = response.data.elements));
    }
    
    findAllByCep(cep) {
        return http.get(`${path}/localidade/filter?cep=${cep}`, {headers: this.headers}).then(response => (this.data = response.data.elements));
    }
    
    findAllById(id) {
        return http.get(`${path}/localidade/findById?id=${id}`, {headers: this.headers});
    }

    findAllLograByCep(cep) {
        return http.get(`${path}/logradouro/findByCep?cep=${cep}`, {headers: this.headers});
    }

}

export default new LoginService();