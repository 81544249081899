import axios from 'axios';
//import store from '@/store/index'; 

//const { protocol, hostname, port } = window.location;

/*const appURL = process.env.NODE_ENV === 'production'
  ? `${protocol}//${hostname}${port ? `:${port}` : ''}/api`
  : process.env.VUE_APP_API;*/

  /*console.log('a');
  console.log(store.getters.serverApi);

  const appURL = 'http://localhost:8080/api';*/


export const http = axios;
