import { createApp } from 'vue'
import App from './App.vue'
import AutoComplete from 'primevue/autocomplete';
import axios from 'axios'
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import PrimeVue from 'primevue/config';
import ProgressSpinner from 'primevue/progressspinner';
import router from './router'
import store from './store';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import DataView from 'primevue/dataview';
import VueAxios from 'vue-axios'

import "primeflex/primeflex.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
////import 'primevue/resources/themes/saga-blue/theme.css'
// import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css'
import '@/styles.scss';


async function bootstrap() {

  const response = await axios.get('/backend.json');

  //seta o path do axios conforme o arquivo externo de configuração
  axios.defaults.baseURL = response.data.serverApi;

  const app = createApp(App);
  app.use(PrimeVue, {
    locale: {
      startsWith: 'Inicia com',
      contains: 'Contem',
      notContains: 'Não Contem',
      endsWith: 'Termina com',
      equals: 'Igual',
      notEquals: 'Diferente',
      noFilter: 'Sem Filtro',
      lt: 'Menor que',
      lte: 'Menor ou igual a',
      gt: 'Maior que',
      gte: 'Maior ou igual a',
      dateIs: 'Hoje',
      dateIsNot: 'Hoje não é',
      dateBefore: 'Data é anterior',
      dateAfter: 'Date é posterior',
      clear: 'limpar',
      apply: 'Aplicar',
      matchAll: 'Combinar Todos',
      matchAny: 'Combinar Todos',
      addRule: 'Adicionar Regra',
      removeRule: 'Remover Regra',
      accept: 'Sim',
      reject: 'Não',
      choose: 'Selecionar',
      upload: 'Carregar',
      cancel: 'Cancelar',
      dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sexta", "Sab"],
      dayNamesMin: ["Do", "Se", "Te", "Qua", "Qui", "Se", "Sa"],
      monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      today: 'Hoje',
      weekHeader: 'Se',
      firstDayOfWeek: 0,
      dateFormat: 'dd/MM/yyyy',
      weak: 'Semana',
      medium: 'Médio',
      strong: 'Forte',
      passwordPrompt: 'Informe a senha',
      emptyFilterMessage: 'Sem resultados',
      emptyMessage: 'Sem opções'
    }
  });
  app.use(router);
  app.use(VueAxios, axios);
  app.use(ToastService);
  app.component('Card', Card);
  app.component('Dialog', Dialog);
  app.component('InputText', InputText);
  app.component('InputMask', InputMask);
  app.component('Password', Password);
  app.component('Toast', Toast);
  app.component('Menubar', Menubar);
  app.component('Button', Button);
  app.component('Menu', Menu);
  app.component('DataTable', DataTable);
  app.component('Column', Column);
  app.component('AutoComplete', AutoComplete);
  app.component('Dropdown', Dropdown);
  app.component('ProgressSpinner', ProgressSpinner);
  app.component('Panel', Panel);
  app.component('Message', Message);
  app.component('Calendar', Calendar);
  app.component('TabView', TabView);
  app.component('TabPanel', TabPanel);
  app.component('Textarea', Textarea);
  app.component('DataView', DataView);
  //app.provide('identClient', response.data.idCliente)

  //inicializa os dados do store com as configruações presentes no arquivo externo
  store.commit('SET_DATA', response.data);
  app.use(store);

  app.mount('#app');
}

bootstrap();